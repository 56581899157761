<template>
    <div>
        <Navbar :page="!$store.state.user.cliente ? 'Editar gestão de risco' : 'Editar analisar risco'" link="/riscos" :nameLink="!$store.state.user.cliente ? 'Gestão de riscos' : 'Analisar Riscos'" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <div class="mt-5 md:mt-0 md:col-span-2">


                <div v-if="form.atividade" class="grid grid-cols-12 gap-2 bg-gray-200 px-2 py-2 rounded">
                    <div class="col-span-12">
                        <h2 class="text-xl font-semibold mb-2">Informações preliminares</h2>
                    </div>
                    <div class="col-span-12 md:col-span-6 text-sm">
                        <div class="flex">
                            <svg class="inline mr-1 -mt-1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path></svg>
                            <h2 class="font-semibold my-1">Atividade: <span class="font-normal">{{ form.atividade.nome ? form.atividade.nome : '' }}</span></h2>
                        </div>
                        
                        <div class="flex">
                            <svg class="inline mr-1 -mt-1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path></svg>
                            <h2 class="font-semibold my-1">Setor: <span class="font-normal">{{ form.atividade.setor ? form.atividade.setor.nome : '' }}</span></h2>
                        </div>
                        
                        <div class="flex">
                            <svg class="inline mr-1 -mt-1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path></svg>
                            <h2 class="font-semibold my-1">Responsável: <span class="font-normal">{{ form.atividade.responsavel ? form.atividade.responsavel.nome : '' }}</span></h2>
                        </div>
                        <div class="flex">
                            <svg class="inline mr-1 -mt-1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path></svg>
                            <h2 class="font-semibold my-1">Quantidade dados mapeados: <span class="font-normal">{{ form.qtdDados }}</span></h2>
                        </div>
                        <div class="flex">
                            <svg class="inline mr-1 -mt-1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path></svg>
                            <h2 class="font-semibold my-1">Quantidade dados sensíveis: <span class="font-normal">{{ form.qtdDadosSensiveis }}</span></h2>
                        </div>
                        <div class="flex">
                            <svg class="inline mr-1 -mt-1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path></svg>
                            <h2 class="font-semibold my-1">Quantidade dados críticos: <span class="font-normal">{{ form.qtdDadosCriticos }}</span></h2>
                        </div>
                    </div>
                    
                    <div class="col-span-12 md:col-span-6 text-sm">
                        <div class="flex">
                            <svg class="inline mr-1 -mt-1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path></svg>
                            <h2 class="font-semibold my-1">Perfil do titular: 
                                <span class="font-normal">
                                        {{ form.atividade.tipotitular.map(t => t.tipo).join(', ') }}
                                </span>
                            </h2>
                        </div>
                        
                        <div class="flex" v-if="form.atividade.abrangenciaGeografica">
                            <svg class="inline mr-1 -mt-1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path></svg>
                            <h2 class="font-semibold my-1">Abrangência geográfica: 
                                <span class="font-normal">{{ form.atividade.abrangenciaGeografica ? form.atividade.abrangenciaGeografica : '' }}</span>
                            </h2>
                        </div>
                        
                        <div class="flex">
                            <svg class="inline mr-1 -mt-1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path></svg>
                            <h2 class="font-semibold my-1">
                                Grau de risco atual: 
                                <span class="px-1 bg-green-500 text-white" v-if="form.grauDeRisco === 'muitobaixa'">Muito Baixo</span>
                                <span class="px-1 bg-green-400 text-white" v-if="form.grauDeRisco === 'baixo'">Baixo</span>
                                <span class="px-1 bg-yellow-500 text-white" v-if="form.grauDeRisco === 'medio'">Médio</span>
                                <span class="px-1 bg-red-400 text-white" v-if="form.grauDeRisco === 'alto'">Alto</span>
                                <span class="px-1 bg-red-500 text-white" v-if="form.grauDeRisco === 'muitoalto'">Muito Alto</span>
                            </h2>
                        </div>
                        <div class="flex">
                            <svg class="inline mr-1 -mt-1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path></svg>
                            <h2 class=" my-1 flex flex-col">
                                <span class="font-semibold">
                                    Motivos para analisar o risco nesta atividade (segundo a ANPD):
                                </span>

                                <div>
                                    <span v-if="form.opcaomotivoAnalise.dadosSensiveis">
                                        <span> Conter dados sensíveis; </span>
                                    </span>
                                    
                                    <span v-if="form.opcaomotivoAnalise.titularMenor">
                                        <span> Titular menor de 18, vulnerável ou idoso; </span>
                                    </span>
                                    
                                    <span v-if="form.opcaomotivoAnalise.frequenciaDiaria">
                                        <span> Frequência diária da atividade; </span>
                                    </span>
                                    
                                    <span v-if="form.opcaomotivoAnalise.baseLegalLegitmo">
                                        <span> Base legal Legítimo Interesse; </span>
                                    </span>
                                    
                                    <span v-if="form.opcaomotivoAnalise.existedecisaoAutomatizada">
                                        <span> Existe decisão automatizada; </span>
                                    </span>
                                </div>
                            </h2>
                        </div>
                        <div class="flex">
                            <svg class="inline mr-1 -mt-1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path></svg>
                            <h2 class="font-semibold my-1">Quantidade de titulares: <span class="font-normal">{{ formatIntNumber(form.totalTitulares || 0) || 0 }}</span></h2>
                        </div>
                    </div>
                    <div v-if="!$store.state.user.cliente" class="col-span-12">
                        <router-link :to="`/mapeamento/form/${form.atividade._id}`" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                            Visualizar atividade
                        </router-link>
                    </div>
                </div>
                <hr class="my-5"/>
                <form action="#" method="POST">
                        <div class="grid grid-cols-12 gap-6 mt-3">
                            <div v-if="false" class="col-span-12 md:col-span-6" :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                <label for="ameacas" class="block text-sm font-medium mb-3">
                                    Motivo para se analisar o risco nesta atividade
                                </label>
                                <label for="dadossensiveis" class="block mb-3">
                                    <input type="checkbox" class="form-check" id="dadossensiveis" :disabled="$store.state.user.cliente ? true : false" v-model="form.opcaomotivoAnalise.dadosSensiveis">
                                    <span class="ml-2">Conter dados sensíveis</span>
                                </label>
                                <label for="titularmenor" class="block mb-3">
                                    <input type="checkbox" class="form-check" id="titularmenor" :disabled="$store.state.user.cliente ? true : false" v-model="form.opcaomotivoAnalise.titularMenor">
                                    <span class="ml-2">Titular menor de 18, vulnerável ou idoso</span>
                                </label>
                                <label for="diaria" class="block mb-3">
                                    <input type="checkbox" class="form-check" id="diaria" :disabled="$store.state.user.cliente ? true : false" v-model="form.opcaomotivoAnalise.frequenciaDiaria">
                                    <span class="ml-2">Frequência diária da atividade</span>
                                </label>
                                <label for="legitimointeresse" class="block mb-3">
                                    <input type="checkbox" class="form-check" id="legitimointeresse" :disabled="$store.state.user.cliente ? true : false" v-model="form.opcaomotivoAnalise.baseLegalLegitmo">
                                    <span class="ml-2">Base legal Legítimo Interesse</span>
                                </label>
                                <label for="existedecisaoAutomatizada" class="block mb-3">
                                    <input type="checkbox" class="form-check" id="existedecisaoAutomatizada" :disabled="$store.state.user.cliente ? true : false" v-model="form.opcaomotivoAnalise.existedecisaoAutomatizada">
                                    <span class="ml-2">Existe decisão automatizada</span>
                                </label>
                                <label for="outro" class="block mb-3">
                                    <input type="checkbox" class="form-check" id="outro" :disabled="$store.state.user.cliente ? true : false" v-model="form.opcaomotivoAnalise.outro" >
                                    <span class="ml-2">Outro</span>
                                </label>
                                <div v-if="form.opcaomotivoAnalise.outro">
                                    <button @click="form.motivosAnalise.push('')" type="button" class="inline-flex justify-center text-sm py-1 px-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                                        Adicionar motivo
                                    </button>
                                    <div class="flex" v-for="(motivo, idx) in form.motivosAnalise" :key="idx">
                                        <input rows="3" :disabled="$store.state.user.cliente ? true : false" placeholder="Motivo" v-model="form.motivosAnalise[idx]" type="text" name="motivoAnalise" id="motivoAnalise" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                                        <button type="button" @click="form.motivosAnalise.splice(idx, 1)" class="text-xs text-white hover:bg-red-600 bg-red-500 rounded px-3 h-8 mx-4 mt-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="grid grid-cols-12 gap-x-2 mt-3">
                            <div class="col-span-12 md:col-span-6">
                                <label v-tooltip="{ content: tooltip['1'], html: true, placement: 'bottom' }" for="ativos" class="inline text-sm font-medium mb-3">
                                    <b>1</b>
                                    Selecione os Ativo em risco nesta atividade
                                    <svg class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256">
                                        <path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path>
                                    </svg> 
                                </label>
                                <svg @click="novoItem('Ativos')" xmlns="http://www.w3.org/2000/svg" class="mx-1 h-6 inline text-green-600" fill="#059669" viewBox="0 0 256 256" >
                                    <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                </svg>
                                <multiselect v-model="form.ativos" :options="ativos" :multiple="true" :close-on-select="false" label="nome" trackBy="_id" id="ativos" placeholder="selecione as opções" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />
                            </div>
                        </div>
                        <div class="grid grid-cols-12 gap-x-2 mt-3">
                            <div class="col-span-12 md:col-span-6">
                                <label v-tooltip="{ content: tooltip['2'], html: true, placement: 'bottom' }" for="vulnerabilidades" class="inline text-sm font-medium mb-3">
                                    <b>2</b> 
                                    Selecione as Vulnerabilidades 
                                    <svg class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256">
                                        <path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path>
                                    </svg> 
                                </label>
                                <svg @click="novoItem('Vulnerabilidades')" xmlns="http://www.w3.org/2000/svg" class="mx-1 h-6 inline text-green-600" fill="#059669" viewBox="0 0 256 256">
                                    <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                </svg>
                                <multiselect @input="onSelectVulnerabilidade" v-model="form.vulnerabilidades" :options="vulnerabilidades" :multiple="true" :close-on-select="false" label="nome" trackBy="_id" id="ativos" placeholder="selecione as opções" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />
                            </div>

                            <div class="col-span-12 md:col-span-6">
                                <label v-tooltip="{ content: tooltip['3'], html: true, placement: 'bottom' }" for="ameacas" class="inline text-sm font-medium mb-3">
                                    <b>3</b> 
                                    Selecione as Ameaças detectadas na atividade
                                    <svg class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256">
                                        <path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path>
                                    </svg> 
                                </label>
                                <svg @click="novoItem('Ameacas')" xmlns="http://www.w3.org/2000/svg" class="mx-1 h-6 inline text-green-600" fill="#059669" viewBox="0 0 256 256">
                                    <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                </svg>
                                <multiselect v-model="form.ameacas" @select="changeAmeacas" :options="ameacas" :multiple="true" :close-on-select="false" label="nome" trackBy="_id" id="ativos" placeholder="selecione as opções" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />
                            </div>
                        </div>
                        <div class="grid grid-cols-12 gap-2 mt-4">
                            <div class="col-span-12 md:col-span-6">
                                <label for="probabilidade" class="block text-sm font-medium">
                                    <b>4</b> 
                                    Probabilidade de acontecer
                                    <svg @mouseenter="$modal.show('modalProbalidade')" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256">
                                        <path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path>
                                    </svg>  
                                </label>
                                <select v-model="form.probabilidade" name="probabilidade" id="probabilidade" class="mt-2 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option value="muitobaixa">Muito Baixa</option>
                                    <option value="baixo">Baixo</option>
                                    <option value="medio">Médio</option>
                                    <option value="alto">Alto</option>
                                    <option value="muitoalto">Muito Alto</option>
                                </select>
                            </div>
                            <div class="col-span-12 md:col-span-6">
                                <label for="impacto" class="block text-sm font-medium">
                                    <b>5</b> 
                                    Impacto caso aconteça  
                                    <svg @click="$modal.show('modalImpacto')" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256">
                                        <path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path>
                                    </svg> 
                                </label>
                                <select v-model="form.impacto" name="impacto" id="impacto" class="mt-2 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option value="muitobaixa">Muito Baixo</option>
                                    <option value="baixo">Baixo</option>
                                    <option value="medio">Médio</option>
                                    <option value="alto">Alto</option>
                                    <option value="muitoalto">Muito Alto</option>
                                </select>
                            </div>

                            <div class="col-span-12 md:col-span-6">
                                <label v-tooltip="{ content: tooltip['6'], html: true, placement: 'bottom' }" for="impacto_titular" class="inline text-sm font-medium">
                                    <b>6</b> 
                                    Impacto para os titulares
                                    <svg class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256">
                                        <path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path>
                                    </svg> 
                                </label>
                                <svg @click="novoItem('ImpactosTitulares')" xmlns="http://www.w3.org/2000/svg" class="mx-1 h-6 inline text-green-600" fill="#059669" viewBox="0 0 256 256">
                                    <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>

                                </svg>
                                <multiselect v-model="form.impacto_titular" :options="impactos_titular" :multiple="true" :close-on-select="false" label="descricao" trackBy="_id" id="impacto_titular" placeholder="selecione as opções" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />
                            </div>
                            <div class="col-span-12 md:col-span-6">
                                <label v-tooltip="{ content: tooltip['7'], html: true, placement: 'bottom' }" for="impacto_negocio" class="inline text-sm font-medium">
                                    <b>7</b> 
                                    Impacto para o negocio da empresa
                                    <svg class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256">
                                        <path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path>
                                    </svg> 
                                </label>
                                <svg @click="novoItem('ImpactosNegocio')" xmlns="http://www.w3.org/2000/svg" class="mx-1 h-6 inline text-green-600" fill="#059669" viewBox="0 0 256 256">
                                    <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>

                                </svg>
                                <multiselect v-model="form.impacto_negocio" :options="impactos_negocio" :multiple="true" :close-on-select="false" label="descricao" trackBy="_id" id="impacto_negocio" placeholder="selecione as opções" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />
                            </div>

                        </div>

                        <div class="grid grid-cols-12 mt-3 mb-3">
                            <div class="col-span-12 md:col-span-4">
                                <h5 v-tooltip="{ content: tooltip['8'], html: true, placement: 'bottom' }" class="text-base py-2 font-bold">8 O grau de risco da atividade é: <span :style="{ color: statusGrauDeRisco() ? statusGrauDeRisco().color : '' }">{{ statusGrauDeRisco() ? statusGrauDeRisco().text : '' }}</span> </h5>
                            </div>
                        </div>
                        <center>
                            <div class="grid grid-cols-8 md:grid-cols-12">
                                <div class="col-span-2 py-2 w-full border border-b-0">
                                </div>
                                <div class="col-span-5 py-2 w-full border">
                                    <label class="text-xs md:text-base">Impacto</label>
                                </div>
                            </div>
                            
                           

                            <div class="grid grid-cols-8 md:grid-cols-12">
                                <div class="col-span-2 py-2 w-full border border-t-0">
                                    <label class="text-xs md:text-base">Matriz de Risco</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border" :class="this.getImpacto() === 5 ? 'border-2 border-blue-500' : ''">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 5 ? 'p-1 rounded-full':''">5</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border" :class="this.getImpacto() === 10 ? 'border-2 border-blue-500' : ''">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 10 ? 'p-1 rounded-full':''">10</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border" :class="this.getImpacto() === 15 ? 'border-2 border-blue-500' : ''">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 15 ? 'p-1 rounded-full':''">15</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border" :class="this.getImpacto() === 20 ? 'border-2 border-blue-500' : ''">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 20 ? 'p-1 rounded-full':''">20</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border" :class="this.getImpacto() === 25 ? 'border-2 border-blue-500' : ''">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 25 ? 'p-1 rounded-full':''">25</label>
                                </div>
                            </div>

                            <div class="grid grid-cols-8 md:grid-cols-12 relative">
                                <div class="col-span-1 py-2 w-full border border-b-0">
                                    <p class="text-xs md:text-base transform -rotate-90 -bottom-24 relative">Probabilidade</p>
                                </div>
                                
                                <div class="col-span-1 py-2 w-full border" :class="this.getProbabilidade() === 25 ? 'border-2 border-blue-500' : ''">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 25 && this.getProbabilidade() === 25 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">25</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border bg-green-900 text-white">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 125 && this.getProbabilidade() === 25 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">125</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border bg-yellow-500 text-white">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 250 && this.getProbabilidade() === 25 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">250</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border bg-red-600 text-white">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 375 && this.getProbabilidade() === 25 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">375</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border bg-gray-900 text-white">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 500 && this.getProbabilidade() === 25 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">500</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border bg-gray-900 text-white">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 625 && this.getProbabilidade() === 25 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">625</label>
                                </div>
                            </div>

                            <div class="grid grid-cols-8 md:grid-cols-12">
                                <div class="col-span-1 py-2 w-full border border-b-0 border-t-0">
                                    
                                </div>
                                
                                <div class="col-span-1 py-2 w-full border" :class="this.getProbabilidade() === 20 ? 'border-2 border-blue-500' : ''">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 20 && this.getProbabilidade() === 20 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">20</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border bg-green-900 text-white">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 100 && this.getProbabilidade() === 20 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">100</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border bg-yellow-500 text-white">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 200 && this.getProbabilidade() === 20 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">200</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border bg-red-600 text-white">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 300 && this.getProbabilidade() === 20 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">300</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border bg-red-600 text-white">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 400 && this.getProbabilidade() === 20 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">400</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border bg-gray-900 text-white">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 500 && this.getProbabilidade() === 20 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">500</label>
                                </div>
                            </div>

                            <div class="grid grid-cols-8 md:grid-cols-12">
                                <div class="col-span-1 py-2 w-full border border-b-0 border-t-0">
                                    
                                </div>
                                
                                <div class="col-span-1 py-2 w-full border" :class="this.getProbabilidade() === 15 ? 'border-2 border-blue-500' : ''">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 15 && this.getProbabilidade() === 15 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">15</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border bg-green-900 text-white">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 75 && this.getProbabilidade() === 15 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">75</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border bg-green-900 text-white">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 150 && this.getProbabilidade() === 15 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">150</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border bg-yellow-500 text-white">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 225 && this.getProbabilidade() === 15 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">225</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border bg-red-600 text-white">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 300 && this.getProbabilidade() === 15 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">300</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border bg-red-600 text-white">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 375 && this.getProbabilidade() === 15 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">375</label>
                                </div>
                            </div>

                            <div class="grid grid-cols-8 md:grid-cols-12">
                                <div class="col-span-1 py-2 w-full border border-b-0 border-t-0">
                                    
                                </div>
                                
                                <div class="col-span-1 py-2 w-full border" :class="this.getProbabilidade() === 10 ? 'border-2 border-blue-500' : ''">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 10 && this.getProbabilidade() === 10 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">10</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border bg-green-600 text-white">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 50 && this.getProbabilidade() === 10 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">50</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border bg-green-900 text-white">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 100 && this.getProbabilidade() === 10 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">100</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border bg-green-900 text-white">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 150 && this.getProbabilidade() === 10 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">150</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border bg-yellow-500 text-white">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 200 && this.getProbabilidade() === 10 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">200</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border bg-yellow-500 text-white">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 250 && this.getProbabilidade() === 10 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">250</label>
                                </div>
                            </div>

                            <div class="grid grid-cols-8 md:grid-cols-12">
                                <div class="col-span-1 py-2 w-full border border-t-0">
                                    
                                </div>
                               
                                <div class="col-span-1 py-2 w-full border" :class="this.getProbabilidade() === 5 ? 'border-2 border-blue-500' : ''">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 5 && this.getProbabilidade() === 5 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">5</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border bg-green-600 text-white">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 25 && this.getProbabilidade() === 5 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">25</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border bg-green-600 text-white">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 50 && this.getProbabilidade() === 5 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">50</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border bg-green-900 text-white">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 75 && this.getProbabilidade() === 5 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">75</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border bg-green-900 text-white">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 100 && this.getProbabilidade() === 5 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">100</label>
                                </div>
                                <div class="col-span-1 py-2 w-full border bg-green-900 text-white">
                                    <label class="text-xs md:text-base" :class="this.calcularGrauDeRisco() === 125 && this.getProbabilidade() === 5 ? 'border-2 border-blue-500 bg-blue-500 p-1 rounded-full':''">125</label>
                                </div>
                            </div>
                        </center>
                        <div class="grid grid-cols-12 gap-x-2 mt-4">
                            <div class="col-span-12 md:col-span-6">
                                <label v-tooltip="{ content: tooltip['9'], html: true, placement: 'bottom' }" for="condutaResponsavelSetor" class="block text-sm font-medium">
                                    <b>9</b> 
                                    Conduta decidida pelo responsável do setor, frente ao grau de risco
                                    <svg class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256">
                                        <path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path>
                                    </svg> 
                                </label>
                                <select @change="onChangeConduta" v-model="form.condutaResponsavelSetor" name="condutaResponsavelSetor" id="condutaResponsavelSetor" class="mt-2 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option value="Aceitar riscos">Aceitar riscos</option>
                                    <option value="Transferir para terceiros">Transferir para terceiros</option>
                                    <option value="Mitigar riscos">Mitigar riscos</option>

                                    <option value="Evitar o risco">Evitar o risco</option>
                                    <option value="Outra">Outra</option>
                                </select>
                                <input v-if="form.condutaResponsavelSetor === 'Outra'" v-model="form.condutaOutra" type="text" placeholder="Digite a conduta decidida" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>
                            <div class="col-span-12 md:col-span-6" :class="form.condutaResponsavelSetor !== 'Mitigar riscos' ? 'opacity-50' : ''">
                                <label v-tooltip="{ content: tooltip['10'], html: true, placement: 'bottom' }" for="condutas" class="inline text-sm font-medium">
                                    <b>10</b> 
                                    Se a decisão for por mitigar riscos, indique as condutas de mitigação
                                    <svg class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256">
                                        <path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path>
                                    </svg> 
                                </label>
                                <svg @click="novoItem('CondutasMitigacao')" xmlns="http://www.w3.org/2000/svg" class="mx-1 h-6 inline text-green-600" fill="#059669" viewBox="0 0 256 256">
                                    <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>

                                </svg>
                                <multiselect :disabled="form.condutaResponsavelSetor !== 'Mitigar riscos' ? true : false" v-model="form.condutas" :options="condutas" :multiple="true" :close-on-select="false" label="nome" trackBy="_id" id="ativos" placeholder="selecione as opções" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />
                            </div>
                        </div>

                        <div class="grid grid-cols-12 gap-2" >
                            <div class="col-span-12 text-sm font-medium">
                                <b>11</b> 
                                Houve partes interessadas consultadas?
                                <svg @click="form.partesConsultadas.push({ ativa: false, nome: '', funcao: '', empresa: '', obs: '' })" xmlns="http://www.w3.org/2000/svg" class="mx-1 h-6 inline text-green-600" fill="#059669" viewBox="0 0 256 256" >
                                    <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                </svg>
                            </div>
                            <div class="col-span-12">
                                <div class="grid grid-cols-12 gap-2" v-for="(parteConsultada, index) in form.partesConsultadas" :key="index">
                                    <div class="col-span-12 md:col-span-2">
                                        <select v-model="parteConsultada.ativa" required name="parteConsultada_ativada" id="parteConsultada_ativada" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                            <option :value="true">Sim</option>
                                            <option :value="false">Não</option>
                                        </select>
                                    </div>
        
                                    <div class="col-span-12 md:col-span-2" :class="!parteConsultada.ativa ? 'opacity-50' : ''">
                                        <input :disabled="!parteConsultada.ativa" v-model="parteConsultada.nome" placeholder="Nome" type="text" name="parteConsultada_nome" id="parteConsultada_nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                                    </div>

                                    <div class="col-span-12 md:col-span-2" :class="!parteConsultada.ativa ? 'opacity-50' : ''">
                                        <input :disabled="!parteConsultada.ativa" v-model="parteConsultada.funcao" placeholder="Função" type="text" name="parteConsultada_funcao" id="parteConsultada_funcao" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                                    </div>

                                    <div class="col-span-12 md:col-span-2" :class="!parteConsultada.ativa ? 'opacity-50' : ''">
                                        <input :disabled="!parteConsultada.ativa" v-model="parteConsultada.empresa" placeholder="Empresa/Organização" type="text" name="parteConsultada_empresa" id="parteConsultada_empresa" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                                    </div>

                                    <div class="col-span-12 md:col-span-2" :class="!parteConsultada.ativa ? 'opacity-50' : ''">
                                        <textarea 
                                        rows="3"
                                        :disabled="!parteConsultada.ativa" v-model="parteConsultada.obs" placeholder="Observações para o tratamento" name="parteConsultada_obs" id="parteConsultada_obs" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                                        </textarea>
                                    </div>
                                    
                                    <div class="col-span-12 md:col-span-2">
                                        <button @click="form.partesConsultadas.splice(index, 1)" type="button" class="text-white text-center hover:bg-red-600 bg-red-500 rounded-full py-2 px-3 mt-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 256 256"><path d="M216,52H172V40a20,20,0,0,0-20-20H104A20,20,0,0,0,84,40V52H40a4,4,0,0,0,0,8H52V208a12,12,0,0,0,12,12H192a12,12,0,0,0,12-12V60h12a4,4,0,0,0,0-8ZM92,40a12,12,0,0,1,12-12h48a12,12,0,0,1,12,12V52H92ZM196,208a4,4,0,0,1-4,4H64a4,4,0,0,1-4-4V60H196ZM108,104v64a4,4,0,0,1-8,0V104a4,4,0,0,1,8,0Zm48,0v64a4,4,0,0,1-8,0V104a4,4,0,0,1,8,0Z"></path></svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="grid grid-cols-12 gap-x-2 mt-4">
                            
                            <div class="col-span-12 md:col-span-12 mt-6">
                                <label for="presenteRIPD" class="block text-sm font-medium">
                                    <input :disabled="$store.state.user.cliente ? true : false" type="checkbox" v-model="form.presenteRIPD" class="rounded-sm" id="presenteRIPD">
                                    <span class="ml-2">
                                        <b>12</b>  
                                        Incluir esta análise no RIPD/DPIA 
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div class="grid grid-cols-2 mt-5">
                            <div class="px-5 py-3 text-left sm:px-6">
                                <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                    Voltar
                                </button>
                                <button @click="save()" v-if="!form.finalizado || $store.state.user.cliente" type="button" class="inline-flex justify-center py-2 px-6 ml-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-600">
                                    Salvar até aqui
                                </button>
                            </div>
                            <div class="px-5 py-3 text-right sm:px-6">
                                <button v-if="form.respondido && !form.revisado && !$store.state.user.cliente" @click="save('revisado')" type="button" class="inline-flex justify-center py-2 px-6 mr-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400">
                                    Marcar como revisada
                                </button>
                                <button v-if="$store.state.user.cliente" @click="save('cliente')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400">
                                    Finalizar Análise 
                                </button>
                                <button v-if="form.respondido && !$store.state.user.cliente" @click="save('finalizado')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                                    Finalizar revisão
                                </button>
                            </div>
                        </div>
                </form>
            </div>
            <modal name="modalProbalidade" :adaptive="true" :height="'auto'">
                <div id="idmodal" class="px-3 py-5 overflow-auto h-full md:h-auto">
                    <p class="text-sm font-medium">
                        <b>Muito baixa:</b> Em situações muito excepcionais, o evento poderá até ocorrer, mas nada nas circunstâncias indica essa possibilidade.
                    </p>
                    <p class="text-sm font-medium mt-1">
                        <b>Baixa:</b> De forma inesperada ou casual, o evento poderá ocorrer, pois as circunstâncias pouco indicam essa possibilidade.
                    </p>
                    <p class="text-sm font-medium mt-1">
                        <b>Média:</b> De alguma forma, o evento poderá ocorrer, pois as circunstâncias indicam moderadamente essa possibilidade.
                    </p>
                    <p class="text-sm font-medium mt-1">
                        <b>Alta:</b> De forma até esperada, o evento poderá ocorrer, pois as circunstâncias indicam fortemente essa possibilidade.
                    </p>
                    <p class="text-sm font-medium mt-1">
                        <b>Muito alta:</b> De forma inequívoca, o evento ocorrerá, as circunstâncias indicam claramente essa possibilidade.
                    </p>
                </div>
            </modal>
            <modal name="modalImpacto" :adaptive="true" :height="'auto'">
                <div id="idmodal" class="px-3 py-5 overflow-auto h-full md:h-auto">
                    <p class="text-sm font-medium">
                        <b>Muito baixo:</b> Impacto insignificante pois identificar um indivíduo usando os dados disponíveis é virtualmente impossível (por exemplo, pesquisar em toda a população usando apenas o primeiro nome de um indivíduo).
                    </p>
                    <p class="text-sm font-medium mt-1">
                        <b>Baixo:</b> Os titulares dos dados não serão afetados ou podem encontrar alguns inconvenientes, os quais serão superados sem problemas (tempo gasto para reinserir informações, aborrecimentos, irritações, etc.).
                    </p>
                    <p class="text-sm font-medium mt-1">
                        <b>Médio:</b> Os titulares dos dados podem encontrar inconvenientes significativos, mas serão capazes de superar apesar de algumas dificuldades (custos adicionais, recusa de acesso aos serviços empresariais, medo, falta de compreensão, stress, pequenas doenças físicas, etc.).
                    </p>
                    <p class="text-sm font-medium mt-1">
                        <b>Alto:</b> Os titulares dos dados podem enfrentar consequências significativas, que devem ser capazes de superar, embora com sérias dificuldades (apropriação indébita de fundos, dificuldades financeiras, perda de acesso à crédito, danos materiais, perda de emprego, intimação, agravamento do estado de saúde, etc.).
                    </p>
                    <p class="text-sm font-medium mt-1">
                    <b>Muito alto:</b> Os titulares dos dados provavelmente terão consequências significativas, ou mesmo irreversíveis (discriminação e danos morais, dívidas substanciais ou incapacidade de trabalhar, doenças físicas ou psicológicas de longo prazo, morte, etc.).
                    </p>
                </div>
            </modal>
            <modal name="novoItem" :adaptive="true" :height="'auto'" :clickToClose="false">
                <div class="px-5 py-5">
                    <div v-if="formNovo.url === 'ativos'">
                        <h2 class="text-xl font-bold mb-4"> Novo Ativo </h2>
                        <div class="grid grid-cols-12 gap-4">
                            <div class="col-span-12">
                                <label for="nome" class="block text-sm font-medium">Nome</label>
                                <input v-model="formNovo.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div> 
                        </div>
                    </div>
                    <div v-if="formNovo.url === 'vulnerabilidades'">
                        <h2 class="text-xl font-bold mb-4"> Nova Vulnerabilidades </h2>
                        <div class="grid grid-cols-12 gap-4">
                            <div class="col-span-12">
                                <label for="nome" class="block text-sm font-medium">Nome</label>
                                <input v-model="formNovo.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div> 
                        </div>
                    </div>
                    <div v-if="formNovo.url === 'vulnerabilidades'">
                        <h2 class="text-xl font-bold mb-4"> Nova Vulnerabilidades </h2>
                        <div class="grid grid-cols-12 gap-4">
                            <div class="col-span-12">
                                <label for="nome" class="block text-sm font-medium">Nome</label>
                                <input v-model="formNovo.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div> 
                            <div class="col-span-12">
                                <label for="ameacas" class="block text-sm font-medium">Ameaças</label>
                                <multiselect v-model="formNovo.ameacas" :options="ameacas" :multiple="true" :close-on-select="false" :taggable="true" label="nome" trackBy="_id" id="ameacas" placeholder="selecione as ameaças" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-1"/>
                            </div>
                            <div class="col-span-12">
                                <label for="condutaMitigacao" class="block text-sm font-medium">Condutas de Mitigação</label>
                                <multiselect v-model="formNovo.condutas" :options="condutas" :multiple="true" :close-on-select="false" :taggable="true" label="nome" trackBy="_id" id="condutaMitigacao" placeholder="selecione as condutas de mitigação" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-1"/>
                            </div>
                        </div>
                    </div>
                    <div v-if="formNovo.url === 'condutasmitigacao'">
                        <h2 class="text-xl font-bold mb-4"> Nova Conduta mitigação </h2>
                        <div class="grid grid-cols-12 gap-4">
                            <div class="col-span-12">
                                <label for="nome" class="block text-sm font-medium">Nome</label>
                                <input v-model="formNovo.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div> 
                        </div>
                    </div>
                    <div v-if="formNovo.url === 'impactos'">
                        <h2 class="text-xl font-bold mb-4"> Novo impacto </h2>
                        <div class="grid grid-cols-12 gap-4">
                            <div class="col-span-12">
                                <label for="descricao" class="block text-sm font-medium">Descrição</label>
                                <input v-model="formNovo.descricao" type="text" name="descricao" id="descricao" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div> 
                        </div>
                    </div>
                    <div v-if="formNovo.url === 'ameacas'">
                        <h2 class="text-xl font-bold mb-4"> Nova Ameaça </h2>
                        <div class="grid grid-cols-12 gap-4">
                            <div class="col-span-12">
                                <label for="nome" class="block text-sm font-medium">Nome</label>
                                <input v-model="formNovo.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div> 
                        </div>
                    </div>
                    <div class="grid grid-cols-2 mt-3">
                        <div class="py-3 text-left">
                            <button @click="$modal.hide('novoItem')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Voltar
                            </button>
                        </div>
                        <div class="py-3 text-right">
                            <button @click="criarItem" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                                Salvar
                            </button>
                        </div>
                    </div>
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            route: 'riscos',
            ativos: [],
            ameacas: [],
            vulnerabilidades: [],
            condutas: [],
            pessoas: [],
            impactos_titular: [],
            impactos_negocio: [],
            formNovo: {},
            form: {
                atividade: null,
                responsavel: null,
                opcaomotivoAnalise: { dadosSensiveis: false, existedecisaoAutomatizada: false, titularMenor: false, frequenciaDiaria: false, baseLegalLegitmo: false, outro: false },
                motivosAnalise: [],
                presenteRIPD: true,
                medidasSeguranca: [],
                ativos: [],
                vulnerabilidades: [],
                ameacas: [],
                condutas: [],
                probabilidade: '',
                impacto: '',
                impacto_titular: null,
                impacto_negocio: null,
                condutaResponsavelSetor: '',
                condutaOutra: '',
                grauDeRisco: '',
                partesConsultadas: [
                    { ativa: false, nome: '', funcao: '', empresa: '', obs: '' }
                ]
            },
            tooltip: {
                '1': '<p class="w-96">Indique aqui o que exatamente está em risco nesta atividade.</p>',
                '2': '<p class="w-96">Vulnerabilidades são fraquezas ou buraco em um programa de segurança que podem ser exploradas por ameaças tornando os riscos maiores ou menores.</p>', 
                '3': '<p class="w-96">Ameaça é qualquer coisa que possa explorar uma vulnerabilidade, intencional ou acidentalmente, e obter, danificar ou destruir um ativo.</p>',
                '4': '<p class="w-96">Qual sua opinião sobre a chance da ameaça se concretizar.</p>',
                '5': '<p class="w-96">Se a ameaça se concretizar qual nível de impacto (nos titulares de dados e na empresa).</p>',
                '6': '<p class="w-96">Impactos atrelados ao titulares de dados.</p>',
                '7': '<p class="w-96">Impactos atrelados ao negocios da empresa.</p>',
                '8': '<p class="w-96">Risco é o potencial de perda, dano ou destruição de um ativo como resultado de uma ameaça que explora uma vulnerabilidade.</p>',
                '9': '<p class="w-96">1) Aceitar riscos = não fazer nada  ignorar o risco 2) Transferir para terceiros = contratar um seguro 3) Mitigar Riscos = tomar atitudes/ações para dimiuir a probabilidade do risco acontecer.</p>',
                '10': '<p class="w-96">Todas as condutas aqui indicadas precisam já estar implementadas ou serem implementadas em algum momento. Não indique o que não será colocado em prática.</p>',
            }
        }
    },
    methods: {
        async save(opcao) {

            if(!this.form.opcaomotivoAnalise.outro) this.form.motivoAnalise = [];

            const method = this.form._id ? 'put' : 'post';

            if(opcao === 'revisado') {
                this.form.revisado = true;
                this.form.status = 'Revisada pelo consultor';
            }

            if (this.form.condutaResponsavelSetor !== 'Mitigar riscos') {
                this.form.condutas = [];
            } else {
                this.form.condutaOutra = '';
            }

            if(opcao !== 'finalizado' && opcao !== 'cliente'){
                if(this.$store.state.user.cliente) this.form.status = 'Em Análise';

                const req = await this.$http.put(`/v1/${this.route}`, this.form);

                if (req.data.success) {
                    this.$vToastify.success("Salvo com sucesso!");
                    this.$router.back();
                } else {
                    this.$vToastify.error(req.data.err);
                }

                return;
            }

            this.$confirm({
                title: opcao === 'cliente' ? 'Responder questionário' : 'Finalizar revisão',
                message: opcao === 'cliente' ? 'Deseja finalizar análise de risco desta atividade?' : `Deseja finalizar revisão ?`,
                button: {
                    no: 'Não',
                    yes: 'Sim',
                },
                callback: async confirm => {
                    
                    if(opcao === 'finalizado') {
                        this.form.finalizado = confirm;
                        this.form.revisado = confirm;
                        if(confirm) this.form.status = 'Finalizado';
                    }

                    if(opcao === 'cliente') this.form.cliente = confirm;

                    const req = await this.$http[method](`/v1/${this.route}`, this.form);

                    if (req.data.success) {
                        this.$vToastify.success("Salvo com sucesso!");
                        this.$router.back();
                    } else {
                        this.$vToastify.error(req.data.err);
                    }

                    return;
                }
            });
        },
        novoItem(item){
            if(item === 'Ativos'){
                this.formNovo = { url: "ativos", nome: "" };
                this.$modal.show('novoItem');
            }
            if(item === 'Vulnerabilidades'){
                this.formNovo = { url: "vulnerabilidades", nome: "" };
                this.$modal.show('novoItem');
            }

            if(item === 'Ameacas') {
                this.formNovo = { url: "ameacas", nome: "", vulnerabilidade: [], condutaMitigacao: [] };
                this.$modal.show('novoItem');
            }

            if(item === 'CondutasMitigacao') {
                this.formNovo = { url: "condutasmitigacao", nome: "" };
                this.$modal.show('novoItem');
            }

            if(item === 'ImpactosTitulares') {
                this.formNovo = { url: "impactos", descricao: "", impactado: "TITULARES DE DADOS" };
                this.$modal.show('novoItem');
            }

            if(item === 'ImpactosNegocio') {
                this.formNovo = { url: "impactos", descricao: "", impactado: "NEGOCIOS DA EMPRESA" };
                this.$modal.show('novoItem');
            }
        },
        async criarItem(){
            const req = await this.$http.post(`/v1/${this.formNovo.url}`, this.formNovo);
            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$modal.hide('novoItem');

                if(this.formNovo.url === 'ativos') {
                    this.ativos.push(req.data.data);
                    this.form.ativos = req.data.data;
                }

                if(this.formNovo.url === 'vulnerabilidades') {
                    this.vulnerabilidades.push(req.data.data);
                    this.form.vulnerabilidades = req.data.data;
                }

                if(this.formNovo.url === 'ameacas') {
                    this.ameacas.push(req.data.data);
                    this.form.ameacas = req.data.data;
                }

                if(this.formNovo.url === "condutasmitigacao") {
                    this.condutas.push(req.data.data);
                    this.form.condutas = req.data.data;
                }

                if(this.formNovo.url === "impactos" && this.formNovo.impactado === "TITULARES DE DADOS") {
                    this.impactos_titular.push(req.data.data);
                    this.form.impacto_titular = req.data.data;
                }

                if(this.formNovo.url === "impactos" && this.formNovo.impactado === "NEGOCIOS DA EMPRESA") {
                    this.impactos_negocio.push(req.data.data);
                    this.form.impacto_negocio = req.data.data;
                }

            } else {
                this.$vToastify.error(req.data.err);
            }
        },
        changeAmeacas(ameaca) {
            if(ameaca.vulnerabilidade) this.form.vulnerabilidades = this.form.vulnerabilidades.concat(ameaca.vulnerabilidade);
            if(ameaca.condutaMitigacao) this.form.condutas = this.form.condutas.concat(ameaca.condutaMitigacao);
        },
        getImpacto(){
            switch (this.form.impacto) {
                case "muitobaixa": return 5;
                case "baixo": return 10;
                case "medio": return 15;
                case "alto": return 20;
                case "muitoalto": return 25;
            }
        },
        getProbabilidade(){
            switch (this.form.probabilidade) {
                case "muitobaixa": return 5;
                case "baixo": return 10;
                case "medio": return 15;
                case "alto": return 20;
                case "muitoalto": return 25;
            }
        },
        calcularGrauDeRisco() {
            return this.getImpacto() * this.getProbabilidade();
        },
        statusGrauDeRisco(){
            if(!this.form.impacto && !this.form.probabilidade) return { text: '', color: 'red' };

            if(this.calcularGrauDeRisco() <= 50){
                this.form.grauDeRisco = 'muitobaixa';
                return { text: 'Muito baixa', color: '#3a9669'};
            }
            if(this.calcularGrauDeRisco() <= 150){
                this.form.grauDeRisco = 'baixo';
                return { text: 'Baixo', color: '#1b4e3b'};
            }
            if(this.calcularGrauDeRisco() <= 250){
                this.form.grauDeRisco = 'medio';
                return { text: 'Médio', color: '#f29e0c'};
            }
            if(this.calcularGrauDeRisco() <= 400){
                this.form.grauDeRisco = 'alto';
                return { text: 'Alto', color: '#dc2626'};
            }
            if(this.calcularGrauDeRisco() > 400){
                this.form.grauDeRisco = 'muitoalto';
                return { text: 'Muito Alto', color: '#111827'};
            }
        },
        onChangeConduta() {
            if (this.form.condutaResponsavelSetor === 'Mitigar riscos') {
                this.onSelectVulnerabilidade(this.form.vulnerabilidades);
            } else {
                this.form.condutas = [];
            }
        },
        onSelectVulnerabilidade (itens) {

            itens?.forEach(item => {
                for (let i = 0; i < item.ameacas.length; i++){
                    const a = item.ameacas[i];
                   
                    if (!this.form.ameacas.some(ame => ame.nome === a.nome)) {
                        this.form.ameacas.push(a);
                    }
                }

                for (let i = 0; i < item.condutas.length; i++){
                    const c = item.condutas[i];
                   
                    if (!this.form.condutas.some(cdt => cdt.nome === c.nome)) {
                        this.form.condutas.push(c);
                    }
                }
            });

            console.log(this.form);
        },
    },
    async beforeMount() {
        const id = this.$route.params.id;

        const listAtivos = await this.$http.post(`/v1/ativos/list`, { all: true});
        this.ativos = listAtivos.data.data;

        const listVulnerabilidades = await this.$http.post(`/v1/vulnerabilidades/list`, { all: true});
        this.vulnerabilidades = listVulnerabilidades.data.data;

        const listAmeacas = await this.$http.post(`/v1/ameacas/list`, { all: true});
        this.ameacas = listAmeacas.data.data;

        const listCondutas = await this.$http.post(`/v1/condutasmitigacao/list`, { all: true});
        this.condutas = listCondutas.data.data;

        const listPessoas = await this.$http.post(`/v1/pessoas/list`, { all: true});
        this.pessoas = listPessoas.data.data;

        const listimpactosTitular = await this.$http.post(`/v1/impactos/list`, { all: true, impactado: "TITULARES DE DADOS"});
        this.impactos_titular = listimpactosTitular.data.data;

        const listimpactosNegocios = await this.$http.post(`/v1/impactos/list`, { all: true, impactado: "NEGOCIOS DA EMPRESA"});
        this.impactos_negocio = listimpactosNegocios.data.data;
        
        if (id) {
            const req = await this.$http.get(`/v1/${this.route}/${id}`);
            this.form = req.data;
            if(!this.form.opcaomotivoAnalise.outro) this.form.motivosAnalise = [];

            if(!this.form.partesConsultadas.length) this.form.partesConsultadas = [
                {
                    ativa: false, 
                    nome: '',
                    funcao: '',
                    empresa: '',
                    obs: ''
                }
            ];


            if (this.form.vulnerabilidades && this.form.vulnerabilidades.length) {
                this.onSelectVulnerabilidade(this.form.vulnerabilidades)
            }

        }
    },
}
</script>